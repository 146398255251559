<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 관련 MOC 목록 -->
      <c-table
        ref="gridmoc"
        title="LBL0003330"
        tableId="gridmoc"
        :columns="gridmoc.columns"
        :data="gridmoc.data"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 추가 -->
            <c-btn v-if="editable" label="LBLADD" icon="add" />
            <!-- 삭제 -->
            <c-btn v-if="editable" label="LBLREMOVE" icon="remove" />
            <!-- 저장 -->
            <c-btn v-if="editable" label="LBLSAVE" icon="save" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'equipment-bom',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {}
      },
    },
  },
  data() {
    return {
      editable: true,
      gridmoc: {
        columns: [
          {
            name: 'initiativeDt',
            field: 'initiativeDt',
            // 발의일
            label: 'LBL0001905',
            align: 'center',
            style: 'width:80px',
            sortable: true
          },
          {
            name: 'mocTitle',
            field: 'mocTitle',
            // 제목
            label: 'LBLTITLE',
            align: 'left',
            sortable: true,
            style: 'width:280px',
            type: 'link',
          },
          {
            name: 'mocStepName',
            field: 'mocStepName',
            // 진행단계
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            style: 'width:90px',
            sortable: true
          },
          {
            name: 'mocTypeName',
            field: 'mocTypeName',
            // 변경구분
            label: 'LBL0001906',
            align: 'center',
            style: 'width:70px',
            sortable: true
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getList();
    },
    getList() {
      this.gridmoc.data = [
        {
          col1: 'MOC-00001',
          col2: '변경검토1',
          col3: '발의',
        },
      ]
    },
  }
};
</script>