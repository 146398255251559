var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-table",
          {
            ref: "gridmoc",
            attrs: {
              title: "LBL0003330",
              tableId: "gridmoc",
              columns: _vm.gridmoc.columns,
              data: _vm.gridmoc.data,
              isFullScreen: false,
              columnSetting: false,
              filtering: false,
            },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable
                      ? _c("c-btn", { attrs: { label: "LBLADD", icon: "add" } })
                      : _vm._e(),
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "LBLREMOVE", icon: "remove" },
                        })
                      : _vm._e(),
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "LBLSAVE", icon: "save" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }